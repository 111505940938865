import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { useGLTF, useTexture, Loader, Environment, useFBX, useAnimations, PerspectiveCamera } from '@react-three/drei';
import { MeshStandardMaterial } from 'three/src/materials/MeshStandardMaterial';
import { LinearEncoding, sRGBEncoding } from 'three/src/constants';
import { LineBasicMaterial, MeshPhysicalMaterial, Vector2, WebGLRenderer, AnimationClip,BooleanKeyframeTrack,
  ColorKeyframeTrack,
  NumberKeyframeTrack,
  Vector3,
  VectorKeyframeTrack , DoubleSide} from 'three';
import ReactAudioPlayer from 'react-audio-player';
import { OrbitControls } from '@react-three/drei';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import './App.css';
import * as THREE from 'three';
import { saveAs } from 'file-saver'; 

//meshopt
import { useLoader} from '@react-three/fiber';
import { MeshoptDecoder } from 'meshoptimizer';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { axiosInstance, getToken, refreshToken, getTokenOrRefresh } from './axiosLib'; // Import the custom axios instance
import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
import Flag from 'react-world-flags';
const speechsdk = require('microsoft-cognitiveservices-speech-sdk')
export let microphoneStream;

const _ = require('lodash');
export let thread = '';
export let renderer = new WebGLRenderer({ antialias: true });
export let light_applied = false; //flag for turing on the lights settings. Default value is light off
export let recognizer = null;
export let welcomeMessageExecuted = false;  //
export let s3_bucket = process.env.REACT_APP_S3_BUCKET;



// Get Recording Screen settings
let params = new URLSearchParams(window.location.search);
export let recording = params.get('recording');
export let duration = params.get('duration');
export let recordingMessage = params.get('message');

//host settings
let host = '';

THREE.Cache.enabled = true;

// MS Recognizer init
 const setupRecognizer = async () => {
  try {
    const tokenObj = await getTokenOrRefresh();

    if (!tokenObj.authToken || !tokenObj.region) {
      console.error("Token retrieval failed");
      return null;  // Return null if token retrieval fails
    }

    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
    speechConfig.speechRecognitionLanguage = localStorage.getItem('selectedLanguage');

    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    const Recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
    console.log("Recognizer", Recognizer);
    console.log(Recognizer.speechRecognitionLanguage,localStorage.getItem('selectedLanguage'));


    Recognizer.canceled = (s, e) => {
      console.error(`Speech recognition canceled: ${e.errorDetails || "No error details"}`);
      toast.error(`Speech recognition canceled: ${e.errorDetails || "No error details"}`);
          console.log(Recognizer.speechRecognitionLanguage,localStorage.getItem('selectedLanguage'));

    };

    Recognizer.sessionStopped = (s, e) => {
      console.log("Session stopped:", e);
          console.log(Recognizer.speechRecognitionLanguage,localStorage.getItem('selectedLanguage'));

    };

    Recognizer.sessionStarted = (s, e) => {
      console.log("Session started:", e);
          console.log(Recognizer.speechRecognitionLanguage,localStorage.getItem('selectedLanguage'));

    };

    Recognizer.speechEndDetected = (s, e) => {
      console.log("Speech end detected:", e);
          console.log(Recognizer.speechRecognitionLanguage,localStorage.getItem('selectedLanguage'));

    };

    Recognizer.speechStartDetected = (s, e) => {
      console.log("Speech start detected:", e);
      console.log(Recognizer.speechRecognitionLanguage, localStorage.getItem('selectedLanguage'))
    };


    recognizer = Recognizer;


  } catch (error) {
    console.error("Error setting up recognizer:", error);
    return null;  // Return null if an error occurs
  }
};


// Call backemnd and prepare speech
function makeSpeech(text,  dictionaryBody, dictionaryTeeth, dictionaryEyelashes, bodyPartName, teethPartName, eyelashesPartName) {
  let urlParams = new URLSearchParams(window.location.search);
  let id = urlParams.get('id'); // Gets the value of 'id' from the URL query string
  let s = urlParams.get('s'); 

  return axiosInstance.post(host + '/talk', { text,  dictionaryBody, dictionaryTeeth, dictionaryEyelashes, bodyPartName, teethPartName, eyelashesPartName, id, s });
}

// Check if domain name is valid
function getDomainName(url) {
  try {
    // Check if the URL string includes a protocol
    if (!/^https?:\/\//i.test(url)) {
      url = 'http://' + url;
    }
    const parsedUrl = new URL(url);
    return parsedUrl.hostname; // This will exclude the port number
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
}

async function generateKey(password, salt) {
    const enc = new TextEncoder();
    const keyMaterial = await window.crypto.subtle.importKey(
        "raw",
        enc.encode(password),
        { name: "PBKDF2" },
        false,
        ["deriveKey"]
    );
    return window.crypto.subtle.deriveKey(
        {
            name: "PBKDF2",
            salt: Uint8Array.from(atob(salt), c => c.charCodeAt(0)),
            iterations: 100000,
            hash: "SHA-256"
        },
        keyMaterial,
        { name: "AES-GCM", length: 256 },
        true,
        ["decrypt"]
    );
}

async function decryptFile(fileName, nds) {
    try {

        let urlParams = new URLSearchParams(window.location.search);
        let id = urlParams.get('id'); // Gets the value of 'id' from the URL query string
        let s = urlParams.get('s'); 

        if(!id || !s){
          console.error("provide correct parameters for id and s");
        } 

        const response = await axiosInstance.get(`/encrypt`, {
            params: { fileName: fileName, "id": id, "s":s }
        });

        const { encryptedData, salt, iv, tag } = response.data;
        const key = await generateKey(nds, salt);

        const ivArray = Uint8Array.from(atob(iv), c => c.charCodeAt(0));
        const encryptedArray = Uint8Array.from(atob(encryptedData), c => c.charCodeAt(0));
        const tagArray = Uint8Array.from(atob(tag), c => c.charCodeAt(0));

        // Concatenate the encrypted data and the tag for decryption
        const ciphertextWithTag = new Uint8Array([...encryptedArray, ...tagArray]);

        const decrypted = await window.crypto.subtle.decrypt(
            {
                name: "AES-GCM",
                iv: ivArray,
            },
            key,
            ciphertextWithTag
        );

        const c = [
            { position: 0, byte: 0x67 }, 
            { position: 1, byte: 0x6C }, 
            { position: 2, byte: 0x54 }, 
            { position: 3, byte: 0x46 }, 
        ];

        const ca = new Uint8Array(decrypted);
        c.forEach(co => {
            ca[co.position] = co.byte;
        });

        const cb = new Blob([ca], { type: 'model/gltf-binary' });
        console.log(URL.createObjectURL(cb));
        return URL.createObjectURL(cb);
    } catch (error) {
        console.error('Error loading file:', error);
        throw error;
    }
}


//Get animations
function getAnimationData(mode, morphTargetDictionaryBody, part, mixer, idleClips) {

  return axiosInstance.post(host + '/createAnimation', {
    "mode": mode,
    "morphTargetDictionaryBody": morphTargetDictionaryBody,
    "part": part
  })
  .then(response => {
    console.log("response from /createAnimation", response.data);
    const animationData = AnimationClip.parse(response.data);

    // Assuming mixer and idleClips are passed correctly and accessible here
    let idleClipAction = mixer.clipAction(idleClips[0]);
    idleClipAction.play();

    let blinkClip = animationData;
    let blinkAction = mixer.clipAction(blinkClip);
    blinkAction.play();

  })
  .catch(error => {
    console.error("Error fetching animation data", error);
    throw error; // Rethrow the error if needed elsewhere
  });
}

// Load Digital Human 
function Avatar({ avatar_url, speak, setSpeak, text, setAudioSource, playing, setIsAnimationLoaded,audioPlayer }) {


  // Retrieve the data from localStorage
  const set = localStorage.getItem('set');

  if (!set) {
    
    console.error('no data found for set in localStorage');

  } 

// get app settings
  const parsedDataSet = JSON.parse(set);

  // Asign Default values 
  const {
    humanId,
    humanName,
    texturesLocation,
    modelNamePrefix,
    hgBodyName,
    hgTeethLowerName,
    hgEyelashesName,
    hgHaircardsMeshName,
    hgDressNamePrefix,
    hgCapName,
    cameraFov,
    cameraLeftRight,
    cameraUpDownSetting,
    cameraForwardBackward,
    hdriDefault,
    backgroundDefault,
    profilePicture,
    languagesDefault,
    voicesDefault,
    isActive,
    createdAt

  } = parsedDataSet[0].human;

  //Asign customized values
  const {welcomeMessage, micOnStart, customerDomain, backendUrl, frontendUrl, background, hairColour, eyesColour, dressColour,
  browsColour,eyelashesColour,customerId} = parsedDataSet[0];
  
 
var iFrameDetection = (window === window.parent) ? false : true;

  if(iFrameDetection){
    console.log('running the app in iframe')
    let domain = getDomainName(document.referrer);

    if( (domain != getDomainName(customerDomain)) && (domain != getDomainName(frontendUrl)) ){
        alert('This domain: ' +domain + ' is not allowed. Please change your settings');
        avatar_url = "";
    }

  }else{
    //console.log('loading the FE url');
  }

  let texturesPath =  texturesLocation + modelNamePrefix;
  host = backendUrl;


  // MeshoptDecoder
  const gltf = useLoader(GLTFLoader, avatar_url, (loader) => {
    // Configure the loader to use MeshOpt decoding
    loader.setMeshoptDecoder(MeshoptDecoder);
    // For some specific meshopt options, we can set them here
    // For example:
    // loader.setMeshoptOptions({ decodeNormals: true });
  });
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;
  let morphTargetElashes = null;

  const textures = useTexture([
    texturesPath + "_body_base_color.png",
    texturesPath + "_body_normal.png",
    texturesPath + "_body_roughness.png",
    texturesPath + "_body_specular.png",
    texturesPath + "_eyes_base_color.png",
          texturesLocation + "alpha.png",
          texturesLocation + "albedo03.png",
          texturesLocation + "normal.png",
    texturesPath + "_hairHG_Haircap_Brows_alpha.png",
    texturesPath + "_hairHG_Haircap_Brows_base_color.png",
    texturesPath + "_hairHG_Haircap_Brows_normal.png",
          texturesLocation + "alpha.png",
          texturesLocation + "albedo03.png",
          texturesLocation + "normal.png",
    texturesPath + "_" + hgDressNamePrefix + "_base_color.png",
    texturesPath + "_" + hgDressNamePrefix + "_normal.png",
    texturesPath + "_" + hgDressNamePrefix + "_roughness.png",
    texturesPath + "_lower_teeth_base_color.png",
    texturesPath + "_lower_teeth_normal.png",
    texturesPath + "_lower_teeth_roughness.png",
    texturesPath + "_upper_teeth_base_color.png",
    texturesPath + "_upper_teeth_normal.png",
    texturesPath + "_upper_teeth_roughness.png",
  ]);

  textures.forEach(t => {
    t.encoding = sRGBEncoding;
    t.flipY = false;
    t.anisotropy = renderer.capabilities.getMaxAnisotropy();

  });

  // Applying Additional configurations for specific textures. This improve quality
  const [
    bodyBaseColor, bodyNormal, bodyRoughness, bodySpecular,
    eyesBase, hairHaircardsAlphaTexture, hairHaircardsBaseTexture, hairHaircardsNormalTexture,
    browsAlphaTexture, browsBaseTexture, browsNormalTexture,
    eyelashesAlphaTexture, eyelashesBaseTexture, eyelashesNormalTexture,
    dressBaseTexture, dressNormalTexture, dressRoughnessTexture,
    teethLowerBaseTexture, teethLowerNormalTexture, teethLowerRoughnessTexture,
    teethUpperBaseTexture, teethUpperNormalTexture, teethUpperRoughnessTexture,
  ] = textures;

  bodyNormal.encoding = LinearEncoding;
  browsAlphaTexture.encoding = LinearEncoding;
  browsNormalTexture.encoding = LinearEncoding;
  teethUpperNormalTexture.encoding = LinearEncoding;
  teethLowerNormalTexture.encoding = LinearEncoding;
  dressNormalTexture.encoding = LinearEncoding;
  hairHaircardsBaseTexture.minFilter = THREE.LinearMipmapLinearFilter;
  hairHaircardsBaseTexture.magFilter = THREE.LinearFilter;
  hairHaircardsBaseTexture.wrapS = hairHaircardsBaseTexture.wrapT = THREE.ClampToEdgeWrapping;

  gltf.scene.traverse(node => {

    if (node.isMesh || node.isSkinnedMesh) {
     // console.log(node.name, node.position); //Debug meshes

      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;


      //Applying textures
      if (node.name.includes("Body")) {
        node.material = new MeshPhysicalMaterial({
          map: bodyBaseColor,
          normalMap: bodyNormal,
          roughnessMap: bodyRoughness,
          roughness: 1.7,
          envMapIntensity: 0.8,
        });
        morphTargetDictionaryBody = node.morphTargetDictionary;
      }

      if (node.name.includes("Eyes")) {
        node.material = new MeshStandardMaterial({
          map: eyesBase,
          roughness: 0.1,
          envMapIntensity: 0.5,
        });
      }

      if (node.name.includes("TeethUpper")) {
        node.material = new MeshStandardMaterial({
          map: teethUpperBaseTexture,
          normalMap: teethUpperNormalTexture,
          roughnessMap: teethUpperRoughnessTexture,
          metalness: 0.0,
          roughness: 0.3,
          envMapIntensity: 1,
        });
      }

      if (node.name.includes("TeethLower")) {
        node.material = new MeshStandardMaterial({
          map: teethLowerBaseTexture,
          normalMap: teethLowerNormalTexture,
          roughnessMap: teethLowerRoughnessTexture,
          metalness: 0.0,
          roughness: 0.3,
          envMapIntensity: 1,
        });
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }

      if (node.name.includes(hgHaircardsMeshName)) {
        node.material = new MeshStandardMaterial({
          map: hairHaircardsBaseTexture,
          alphaMap: hairHaircardsAlphaTexture,
          normalMap: hairHaircardsNormalTexture,
          transparent: true,
          side: THREE.DoubleSide,
          depthWrite: false,
        });
      }

      if (node.name.includes(hgDressNamePrefix)) {
        node.material = new MeshStandardMaterial({
          map: dressBaseTexture,
          normalMap: dressNormalTexture,
          roughnessMap: dressRoughnessTexture,
          side: THREE.DoubleSide,
          envMapIntensity: 0.5,
        });
      }
    }
  });


  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), [gltf.scene]);

  useEffect(() => {
    if (speak === false) return;
    makeSpeech(text, morphTargetDictionaryBody, morphTargetDictionaryLowerTeeth, morphTargetElashes, hgBodyName, hgTeethLowerName, hgEyelashesName).then(response => {
 
      let blendData = response.data.additional_data.blendData; 
      let newClips = [AnimationClip.parse(response.data.clips[0]),AnimationClip.parse(response.data.clips[1])] ;
      let filename = host + response.data.additional_data.filename; 
      console.log(filename,response.data);
      setClips(newClips);
      setAudioSource(filename);
      setSpeak(false);
      

      //Safari fix duo not  on change event
      audioPlayer.current.audioEl.current.play();


    }).catch(err => {
      console.error(err);
      setSpeak(false);
    });
  }, [speak]);

  let idleFbx = useFBX(s3_bucket+'/idle.fbx');
  let { clips: idleClips } = useAnimations(idleFbx.animations);

  idleClips[0].tracks = _.filter(idleClips[0].tracks, track => {
    return track.name.includes("Head") || track.name.includes("Neck") || track.name.includes("Spine2");
  });

  idleClips[0].tracks = _.map(idleClips[0].tracks, track => {
    if (track.name.includes("Head")) {
      track.name = "head.quaternion";
    }
    if (track.name.includes("Neck")) {
      track.name = "neck.quaternion";
    }
    if (track.name.includes("Spine")) {
      track.name = "spine2.quaternion";
    }
    return track;
  });


useEffect(() => {
  // Initialize face animation HG BODY
  getAnimationData(false, morphTargetDictionaryBody, hgBodyName, mixer, idleClips)
    .then(() => {
     setIsAnimationLoaded(true); // Enable buttons after animation loads
    })
    .catch(err => {
      console.error(err);
      setIsAnimationLoaded(false); // Ensure buttons remain disabled if there's an error
    });

}, [idleClips, mixer, morphTargetDictionaryBody]);
  


  useEffect(() => {
    if (playing === false) return;
    _.each(clips, clip => {
      let clipAction = mixer.clipAction(clip);
      clipAction.setLoop(THREE.LoopOnce);
      clipAction.play();
    });
  }, [playing, clips, mixer]);

  useFrame((state, delta) => {
    mixer.update(delta);
  });


  if(!light_applied){
      //Light settings

      let light = new THREE.DirectionalLight(0xffffff, 1.2);
      light.position.set(0, 1.0, 1.0);
      light.castShadow = true;
      // light.shadow.mapSize.width = 4096; // Higher resolution shadow map
      // light.shadow.mapSize.height = 4096;
      gltf.scene.add(light);

      const ambientLight = new THREE.AmbientLight(0x404040, 8); // Add ambient light for softer lighting
      gltf.scene.add(ambientLight);;
      light_applied = true;

  }

  return (
   <group name="avatar" scale={[2, 2, 2]}> {/* Scale the model up */}
    <primitive object={gltf.scene} dispose={null} />
  </group>
  );
}

const STYLES = {
  area: { position: 'relative', bottom: '0', left: '0', zIndex: 500 },
  speak: { padding: '40px', display: 'block', color: '#FFFFFF', background: '#222222', border: 'None' , position: 'absolute', zIndex: 1000},
  label: { color: '#777777', fontSize: '0.5em' },
};

//Custom Drop Down in the start PopUp
const CustomDropdown = ({ options, selectedValue, onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (code) => {
    onChange(code);
    setIsOpen(false); // Hide the dropdown after selection
  };

  return (
    <div className="custom-dropdown">
      <button 
          className="custom-dropdown-button" 
          onClick={() => setIsOpen(!isOpen)} 
          disabled={disabled} 
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer'
          }}
        >
          {selectedValue ? (
            <Flag code={selectedValue.split('-')[1]} className="flag-icon" />
          ) : (
            'Select Language'
          )}
        </button>
      {isOpen && (
        <div className="custom-dropdown-content">
          {options.map((option, index) => (
            <div key={index} className="custom-dropdown-item" onClick={() => handleSelect(option.code)}>
              <Flag code={option.country} className="flag-icon" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
/*  Custom Drop down in the app. The only difference is margin 0 or 20px  */
const CustomDropdownInternal = ({ options, selectedValue, onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (code) => {
    onChange(code);
    setIsOpen(false); // Hide the dropdown after selection
  };

  return (
    <div className="custom-dropdown">
      <button 
          className="custom-dropdown-button" 
          onClick={() => setIsOpen(!isOpen)} 
          disabled={disabled} 
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer'
          }}
        >
          {selectedValue ? (
            <Flag code={selectedValue.split('-')[1]} className="flag-icon" />
          ) : (
            'Select Language'
          )}
        </button>
      {isOpen && (
        <div className="custom-dropdown-content">
          {options.map((option, index) => (
            <div key={index} className="custom-dropdown-item_internal" onClick={() => handleSelect(option.code)}>
              <Flag code={option.country} className="flag-icon" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Popup = ({ onClose, onOkay, popupTitle, popupText, popupBtn, setSelectedLanguage, isLoading, isAnimationLoaded, parsedLanguages , profilePicture}) => {
  
  const [selectedLanguage, setSelectedLanguageState] = useState('');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguageState(storedLanguage);
    }
  }, []);

  const handleSelectChange = (language) => {
    console.log('handleSelectChange', language);
    setSelectedLanguageState(language);
    setSelectedLanguage(language);
    localStorage.setItem('selectedLanguage', language);
    console.log('in Popup current lang is ', localStorage.getItem('selectedLanguage'));
  };

  const handleButtonClick = () => {
    if (!selectedLanguage && parsedLanguages.length > 1) {
      alert("Please select a language.");
      return;
    }
    onOkay();
    onClose();
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <h2>
        {popupTitle} {(!isAnimationLoaded) && <span className="loading-spinner"></span>}

        </h2>
        <img  style={{width : '60px', height: '60px'}} src={profilePicture}/>
        <p className="popup-text">
          {popupText}
        </p>
         {parsedLanguages.length > 1 && (
            <p> Let's start. Please, select the language you want speak </p>
         )}
        {parsedLanguages.length > 1 && (
          <CustomDropdown options={parsedLanguages} selectedValue={selectedLanguage} onChange={handleSelectChange} disabled={isLoading || !isAnimationLoaded} />
        )}
        <p> </p>
        <button 
          onClick={handleButtonClick} 
          disabled={isLoading || !isAnimationLoaded || (parsedLanguages.length > 1 && !selectedLanguage)} 
          style={{
            opacity: (isLoading || !isAnimationLoaded || (parsedLanguages.length > 1 && !selectedLanguage)) ? 0.5 : 1,
            cursor: (isLoading || !isAnimationLoaded || (parsedLanguages.length > 1 && !selectedLanguage)) ? 'not-allowed' : 'pointer'
          }}
        >
          {popupBtn}
        </button>
      </div>
      <style>{`
        .loading-spinner {
          margin-left: 10px;
          border: 4px solid #f3f3f3;
          border-top: 4px solid #3498db;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          animation: spin 2s linear infinite;
          display: inline-block;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};


function App() {
  const [decryptedGlbUrl, setDecryptedGlbUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [chats, setChats] = useState([{ msg: '', who: 'bot_', exct: '0' }]);
  const [text, setText] = useState("");
  const [msg, setMsg] = useState("");
  const [exct, setexct] = useState("");
  const [load, setLoad] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [visits, setVisits] = useState("--");
  const [cameraFov, setCameraFov] = useState("--");
  const [cameraLeftRight, setCameraLeftRight] = useState("--");
  const [cameraUpDown, setCameraUpDown] = useState("--");
  const [cameraForwardBackward, setCameraForwardBackward] = useState("--");
  const [background, setBackground] = useState("--");
  const [hdriDefault, setHdriDefault] = useState("--");
  const [companyShortOverview, setCompanyShortOverview] = useState("--");
  const [companyLogoLink, setCompanyLogoLink] = useState("--");
  const [showPopup, setShowPopup] = useState(true);
  const [popUpTitle, setPopUpTitle] = useState(true);
  const [popUpText, setPopUpText] = useState(true);
  const [popUpButtonText, setPopUpButtonText] = useState(true);
  const [isListening, setIsListening] = useState(false);
  const micRef = useRef(null);
  const imgMicRef = useRef(null);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en-US');
  const [isAnimationLoaded, setIsAnimationLoaded] = useState(false); // New state
  const [parsedLanguages, setParsedLanguages] = useState('');
  const [s3_bucket, setS3_bucket] = useState('');
  const [profilePicture, setProfilePicture] = useState('');


  //Record video and Show Video settings
  const [showVideo, setShowVideo] = useState(false); // Control video visibility
  const [videoOnStart, setVideoOnStart] = useState(false); 
  const [integration, setIntegration] = useState("");
  const videoRef = useRef(null); // Declare videoRef with useRef
  const [displayedIndices, setDisplayedIndices] = useState([0, 1, 2]); // Initially display first three videos
  const [nextIndex, setNextIndex] = useState(3); // Next video index to display



async function handleVideoClick(clickedIndex) {

  //stop mic
  setTimeout(async () => {
    console.log('Handle Video Click. Stoping the mic')
    console.log('Stopping the mic');
    await stopRecognizer();
    await setIsListening(false);
}, 2000); //stop after 2 seconds the mic. This is needed because onEnded is executed after handleVideoClick. THis way it will garantee that we stop the mic

  stopVideo();
  if (nextIndex < JSON.parse(integration).videos.length) {
    setDisplayedIndices((prevIndices) =>
      prevIndices.map((index) => index === clickedIndex ? nextIndex : index)
    );
    setNextIndex(nextIndex + 1);
  } else {
    // Optionally, loop back to the beginning or disable further replacements
  
  }

  // Set the video to play
  const clickedVideo = JSON.parse(integration).videos[clickedIndex];
  setVideoOnStart(clickedVideo.src);
  setShowVideo(true);
};

//Stoping video
const stopVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause(); // Stops the video
      videoRef.current.currentTime = 0; // Optionally reset the video to the start
      videoRef.current.load(); // Ensures the video is reset (stopped completely)
    }
    setShowVideo(false); // Hides the video
    restartAppAfterVideoEnd(); // restarting Speech regognition 
  };


//Restart speach recognition
async function restartAppAfterVideoEnd() {

  console.log('restarting app after video end. Enable mic')
  setShowVideo(false);
  await stopRecognizer();
  await setupRecognizer();
  await startRecognizerListening();
  setIsListening(true);

}

//Ask user for mic permision
async function requestMicrophoneAccess() {
  try {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Modern browsers
      microphoneStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Microphone access granted');
    } else if (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia) {
      // Legacy browsers
      navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
      navigator.getUserMedia({ audio: true }, 
        function(stream) {
          microphoneStream = stream;
          console.log('Microphone access granted');
          startAudioProcessing(microphoneStream);
        },
        function(error) {
          console.error('Error accessing microphone in legacy browser:', error);
        });
      return; // Exit function early since we are handling the legacy case here
    } else {
      console.log('getUserMedia is not supported in this browser.');
      return;
    }

    // Start processing the audio stream continuously
    startAudioProcessing(microphoneStream);

    // Periodically check if the stream is still active
    setInterval(() => {
      if (microphoneStream && !microphoneStream.active) {
        console.log('Microphone stream became inactive, reinitializing...');
        requestMicrophoneAccess(); // Reinitialize the microphone access if it becomes inactive
      }
    }, 1000); // Check every 1 seconds

  } catch (error) {
    console.error('Error accessing microphone:', error);
  }
}

function startAudioProcessing(stream) {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  if (!AudioContext) {
    console.error('Web Audio API is not supported in this browser.');
    return;
  }

  const audioContext = new AudioContext();
  const source = audioContext.createMediaStreamSource(stream);
  const processor = audioContext.createScriptProcessor(4096, 1, 1);

  processor.onaudioprocess = function(event) {
    const inputData = event.inputBuffer.getChannelData(0);
    // Process the inputData for your needs
    //    console.log('Processing audio data...');
  };

  source.connect(processor);
  processor.connect(audioContext.destination);

  // Handle page visibility changes to maintain the audio context
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
     // audioContext.suspend();
      console.log('Audio context suspended');
    } else {
    //  audioContext.resume();
      console.log('Audio context resumed');
    }
  });
}

function handleGetUserMediaError(error) {
  if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
    toast.error('Microphone access was denied.');
  } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
    toast.error('No microphone was found.');
  } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
    toast.error('Microphone is currently being used by another application.');
  } else {
    toast.error('An error occurred accesing your microphone ');
    console.error(error);
  }
}


   // Function to handle "Okay" button click in the home popup. 
  const handleOkayClick = async () => {

    setShowPopup(false); 
    const storedData = localStorage.getItem('set');
    if (storedData) {
      const parsedData = JSON.parse(storedData);


      let flag_execute_welcome = true; 

      //show initial video  if there are such and recording is OFF
      if(parsedData[0].integration && recording != 'on') {
          let integration_data = JSON.parse(parsedData[0].integration); 


          if(integration_data.videos && integration_data.videos.length >= 1){

              // Find the video with onstart = "1"
              const onStartVideo = integration_data.videos.find(video => video.onstart === "1");

              // Check if an onstart video was found, and set it to play
              if (onStartVideo) {

                  console.log('playing home video ' + onStartVideo.text)
                  setVideoOnStart(onStartVideo.src);
                  setShowVideo(true);
                  flag_execute_welcome = false

              } else {

                //if no home video was selected then play the welcome message
                      flag_execute_welcome = true

               }

          }
      }

      //Record screen is powered from here
      if(recording == "on" && recordingMessage){
          //Welcome message overwriten with the value from URL message param
           setText(recordingMessage);
      }else{

        //This is the default welcome message which will be executed normaly
        setText(parsedData[0].welcomeMessage);
        
      }

      //if there are no initial videos start welcome message or recording is set to ON
      if(flag_execute_welcome == true || recording == 'on'){
          setSpeak(true);
          setexct(0);
          setLoad(false);
   
      }
     

     console.log('Init recognizer object after popup clicked');
     await stopRecognizer();
     await setupRecognizer();



     //screen record starts here
     if(recording == "on"){
        console.log('recording ', recording)
        console.log('duration ', duration)
        startRecording();
     }
    
    }
  };


const parseLanguageCode = (code) => {
  const [lang, country] = code.split('-');
  return { code, display: code, country: country.toUpperCase() };
};

useEffect(() => {
  const initializeTokenAndLoadModel = async () => {
    try {
          

      // First, await getToken
      await getToken();
      
      // Retrieve the data from localStorage
      const storedData = localStorage.getItem('set');

      if (storedData) {
        // Parse the JSON string back into an object
        const parsedData = JSON.parse(storedData);

        // Apply settings
        const humanName = parsedData[0].human.name;
        setCameraUpDown(parsedData[0].human.cameraUpDownSetting);
        setCameraLeftRight(parsedData[0].human.cameraLeftRight);
        setCameraForwardBackward(parsedData[0].human.cameraForwardBackward);
        setCameraFov(parsedData[0].human.cameraFov);
        setBackground(parsedData[0].background);
        setHdriDefault(parsedData[0].human.hdriDefault);
        setCompanyShortOverview(parsedData[0].companyShortOverview);
        setCompanyLogoLink(parsedData[0].companyLogoLink);

       // Set popup title, text, and button text with default values if not present
        setPopUpTitle(parsedData[0].popUpTitle || 'Welcome');
        setPopUpText(parsedData[0].popUpText || 'This will be just like a video call where we can talk face to face. Ask me a question and I’ll do my best to answer it. \n\n  If that sounds ok, please turn on access to your microphone when I request it.\n\nThe speed of your internet connection can have a big impact on the picture quality in the call, \n\n I can find it hard to hear you when you’re in a noisy room, or when there are other conversations going on around you. Please call me from a quiet place.');
        setPopUpButtonText(parsedData[0].popUpButtonText || 'Let\'s talk');

        //get integration info and parse it to Object
        let intParsedData = false;
        if(parsedData[0].integration != null && parsedData[0].integration != ''){
             intParsedData = JSON.parse(parsedData[0].integration);
        }
        
        //prepare integration settings for presenting the video cards in the footer
        if(intParsedData != false && typeof intParsedData === "object" && intParsedData.videos && intParsedData.videos.length > 0 ){

            // Save videos to integration variable
            setIntegration(parsedData[0].integration);

            let videos = JSON.parse(parsedData[0].integration);

              // Initialize displayedIndices and nextIndex based on integration.videos
              // this is needed for the special order when video is clicked. Minimum videos needed are 3 to be displayed in the footer
              const initialDisplayCount = Math.min(3, videos.videos.length);
              setDisplayedIndices(videos.videos.slice(0, initialDisplayCount).map((_, idx) => idx));
              setNextIndex(initialDisplayCount);
        }
        
        //set image location for the avatar profile pic in the popup
        setProfilePicture(parsedData[0].human.profilePicture);

        //extract languages and save them into variable passed to Popup and CustomDropdonw
        let parsedLang = parsedData[0].language.split(',').map(parseLanguageCode);

        setParsedLanguages(parsedLang);

        //if it's only 1 language then set local storage and selectedLang to this language. No need to show select options
        if(parsedLang.length == 1 ){
          localStorage.setItem('selectedLanguage', parsedData[0].language);
          setSelectedLanguage(parsedData[0].language);
        }

        const url = await decryptFile(humanName, parsedData[0].nds);
        setDecryptedGlbUrl(url);
        setIsLoading(false);


      } else {
        console.error('No data found in localStorage');
      }
    } catch (error) {
      console.error('Error initializing token or loading decrypted model:', error);
      setIsLoading(false);
    }
  };

  // Call the combined function
 initializeTokenAndLoadModel();



}, []);


  const getResponse = async (msg) => {
    

    ///KEYWORDS matching for VIDEO functionality starts here      

    //flat that either plays video or calls LLM
    let flag_keyword_for_video_found = false;

    let integrationParsed = false; //flag for avoiding NULL or '' parsing from JSON.parse 

      //parse string to object if there is values
      if(integration != false && integration != '' && integration != null){
           integrationParsed = JSON.parse(integration);

      }
      if(integrationParsed && integrationParsed.videos && integrationParsed.videos.length > 0 ){

        // Check if the recognized text contains any video keyword. Remove any symbols such as . - ! 
            let recognizedText = msg.toLowerCase().replace(/[.,!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?]+/g, "");

            console.log('removed symbols from recognized text' , recognizedText);
            let matchedVideo = integrationParsed.videos.find(video => recognizedText.includes(video.keyword.toLowerCase()));


            if (matchedVideo) {
                console.log('Matched video with speech detected keyword: ', matchedVideo);

                //show the right video
                setVideoOnStart(matchedVideo.src);
                setShowVideo(true);
                  ///stop the mic
                console.log('Stoping the mic from recognized word triger')
                await stopRecognizer();
                await setIsListening(false);
                flag_keyword_for_video_found = true;
            }

      }


      //no keyword found and executing default action

      if(flag_keyword_for_video_found == false){


          micRef.current.disabled = true;
          micRef.current.style.opacity = "0.3";

          if (msg === '') {
            toast.error("Prompt can't be empty.[In some browsers mic may not work]");
            return;
          }
          if (load === true || speak === true) {
            toast.error("Already generating response!");
            return;
          }


           console.log('no keyword found and executing default action')

          //default application flow. Ask LLM for responce
            setChats(chats => [...chats, { msg: msg, who: 'me' }]);
            setMsg("");
            setLoad(true);
            const start = new Date();

            let urlParams = new URLSearchParams(window.location.search);
            let id = urlParams.get('id'); // Gets the value of 'id' from the URL query string
            let s = urlParams.get('s'); 
            axiosInstance.post(host + '/ask', { msg, thread, id, s }).then(response => {
              thread = response.data.thread_id;
              console.log(response, thread);
              const timeTaken = (new Date()) - start;
              setSpeak(true);
              setText(response.data.message);
              setexct(timeTaken / 1000);
              setLoad(false);

              
            });

      }

    
  };

  const getWebsiteVisits = async () => {
    // Implement your logic to get website visits
  };

  useEffect(() => {
    getWebsiteVisits();
  }, []);

  useEffect(() => {
    document.querySelector('.chat-box').scrollTop = document.querySelector('.chat-box').scrollHeight;
  }, [chats]);

  const audioPlayer = useRef();
  const [speak, setSpeak] = useState(false);
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [micActive, setMicActive] = useState(false); // Add this state


  //microsoft speech service

  const playerEnded = async () => {

    console.log('Here in playerEnded -> is listening ', isListening)
    setAudioSource(null);
    setSpeak(false);
    setPlaying(false);
    if (micRef.current) {
      micRef.current.style.opacity = "1.0";
      micRef.current.disabled = false;

      //start mic
      if(!welcomeMessageExecuted){
         await requestMicrophoneAccess();
         console.log('1) Enabeling mic after Play compleated and start speech recognition')
         await startRecognizerListening();
         welcomeMessageExecuted = true;
         await setIsListening(true);   
      }else{

          let mic_element = document.querySelector('.msgbtn#mic_active');
          if (mic_element) {
            console.log('2) Microphone was set to ON.  Power ON the Speech recognition after play is compleated. RequestMic, stop and setup recognizer as well as listening');
            await requestMicrophoneAccess();
            await stopRecognizer();
            await setupRecognizer();
            await startRecognizerListening();
            setIsListening(true);
          } else {
            console.log('3) Microphone was OFF. Speech recognition will stay OFF. ');
            await  setIsListening(false);
            await stopRecognizer();
            console.log('3.1 recognizer was stopped ')

          }
       }
    }

    if(recording == "on"){
      console.log('stop recording after ', duration);
      setTimeout(() => {

        if(!duration){
          duration = 4; //default value
        }
        stopRecording();
      }, duration * 1000);
    }
        
  };

  const playerReady = () => {

    //audioPlayer.current.audioEl.current.play();
    setPlaying(true);
    setChats(chats => [...chats, { msg: text, who: 'bot', exct: exct }]);
  };


  //function that manages recognizer and keeps only one instance of it during the select change event
  async function manageRecognizerOnSelect() {
    if (recognizer) {
      try {
        // New language is set. Destroy and reinitiate the recognizer
        await stopRecognizer();
        await setupRecognizer();
 
        // Turn on speech recognition if the microphone was previously set to active
        let mic_element = document.querySelector('.msgbtn#mic_active');

        if (mic_element) {
          console.log('4) Microphone was set to ON. Turning ON the Speech recognition after play is completed');
          await startRecognizerListening();
          setIsListening(true);
        } else {
          console.log('5) Microphone was OFF. Speech recognition will stay OFF.');
          setIsListening(false);
        }
      } catch (error) {
        console.error('Error managing recognizer:', error);
      }
    }
}
  const handleSelectChange = (language) => {
    console.log('handleSelectChange', language);
    localStorage.setItem('selectedLanguage', language);
    console.log('selected lang in APP is ', localStorage.getItem('selectedLanguage'));
    setSelectedLanguage(language);


    //depending of the status of the mic, manage recognizer
    manageRecognizerOnSelect();
  
  };

//destroy Ms instqnce
const stopRecognizer = async () => {

  if(!recognizer){
    return;
  }
   recognizer.stopContinuousRecognitionAsync(
        () => {
          console.log("Recognition stopped successfully.");
        },
        (err) => {
          console.error("Failed to stop recognition:", err);
          toast.error("Failed to stop recognition. Please try again.");
        }
      );
  
};

//start continues listening
const startRecognizerListening = async () => {
  if(!recognizer){
    return; 
  }
  recognizer.startContinuousRecognitionAsync(
      () => {
        console.log("Recognition started successfully.");
      },
      (err) => {
        console.error("Failed to start recognition:", err);
        toast.error("Failed to start recognition. Please, enable your microphone.");
        imgMicRef.current.src = s3_bucket + "/images/icons/mute.png";
         requestMicrophoneAccess();
         stopRecognizer();
         setupRecognizer();

      }

    );

    recognizer.recognized = async (s, e) => {
        if (e.result.reason === ResultReason.RecognizedSpeech) {


          console.log('Got final result:', e.result.text);
          let res = e.result.text;



            ///KEYWORDS matching for VIDEO functionality starts here
          let integrationParsed = false; //flag for avoiding NULL or '' parsing from JSON.parse 

          //parse string to object if there is values
          if(integration != false && integration != '' && integration != null){
               integrationParsed = JSON.parse(integration);

          }
          
          //show video if keyword is matched and there are such settings in integration field in db
        if(integrationParsed && integrationParsed.videos && integrationParsed.videos.length > 0 ){

            // Check if the recognized text contains any video keyword. Remove any symbols such as . - ! 
            let recognizedText = res.toLowerCase().replace(/[.,!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?]+/g, "");

            console.log('removed symbols from recognized text' , recognizedText);

            // Find the first video with a matching keyword
            let matchedVideo = integrationParsed.videos.find(video => recognizedText.includes(video.keyword.toLowerCase()));

            if (matchedVideo) {
                console.log('Matched video with speech detected keyword: ', matchedVideo);

                //show the right video
                setVideoOnStart(matchedVideo.src);
                setShowVideo(true);
                  ///stop the mic
                console.log('Stoping the mic from recognized word triger')
                await stopRecognizer();
                await setIsListening(false);

              // You can also perform any additional actions with matchedVideo here
            }else {
                setMsg(e.result.text);
                getResponse(e.result.text);
                //stop  mic and process the result found
                await stopRecognizer();
            } 
          }else{
              //execute the request to llm no settings provided
                setMsg(e.result.text);
                getResponse(e.result.text);
                //stop  mic and process the result found
                await stopRecognizer();
            }

        } else if (e.result.reason === ResultReason.NoMatch) {
          console.log('No speech could be recognized.');
        }
      };
  
}

  //Handle on mic click
  const microphone_triger = async () => {
      if (!isListening) {
        console.log('Turn on mic. Recognizer ');
        imgMicRef.current.src =  s3_bucket +"/images/icons/mic.png";
        if(recognizer){
            //start it
            console.log('ic Enabled -> Start active listening')
            await startRecognizerListening();
            await setIsListening(true);
         
        }else{
          console.error('Recognizer is null on mic ON event');
        }

      } else {
        // Turn Off the mic
        console.log('Turn off mic ', recognizer);
       await setIsListening(false);
        imgMicRef.current.src = s3_bucket + "/images/icons/mute.png";
       await stopRecognizer();

      }
};


//RECORDING settings are here
const [isRecording, setIsRecording] = useState(false);
const [mediaRecorder, setMediaRecorder] = useState(null);
const recordedChunks = useRef([]);

// Refs for AudioContext
const audioContextRef = useRef(null);
const audioDestinationRef = useRef(null);
const audioElementRef = useRef(null);
const canvasRef = useRef(null);
  

  //start 3d object (canvas element) recording from passed params in url
 const startRecording = async () => {
  try {
    const canvas = document.querySelector('canvas');
    if (!canvas) {
      toast.error("Canvas element not found!");
      return;
    }

    // Capture the canvas stream at 60 FPS
    const canvasStream = canvas.captureStream(60);

    // Initialize AudioContext if not already
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      audioDestinationRef.current = audioContextRef.current.createMediaStreamDestination();

      const source = audioContextRef.current.createMediaElementSource(audioPlayer.current.audioEl.current);
      // const source = audioContextRef.current.createMediaElementSource(audioElementRef.current);

      console.log("source ", source);

      source.connect(audioDestinationRef.current);
    }

    const audioStream = audioDestinationRef.current.stream;

    // Combine canvas video and audio streams
    const combinedStream = new MediaStream([
      ...canvasStream.getVideoTracks(),
      ...audioStream.getAudioTracks(),
    ]);

    // Define MediaRecorder options for better audio quality
    const options = { 
      mimeType: 'video/webm; codecs=vp9,opus',
      audioBitsPerSecond: 256000, // Higher bitrate for better audio
      videoBitsPerSecond: 5000000  // Higher bitrate for better video
    };

    // Check if the specified MIME type is supported
    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
      toast.warn(`${options.mimeType} is not supported, using default settings`);
      delete options.mimeType;
    }

    // Initialize MediaRecorder
    const recorder = new MediaRecorder(combinedStream, options);

    // Event handler for available data
    recorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        recordedChunks.current.push(e.data);
      }
    };

    // Event handler for stopping the recorder
    recorder.onstop = () => {
      const blob = new Blob(recordedChunks.current, { type: 'video/webm' });
      recordedChunks.current = [];
      saveAs(blob, 'recording.webm'); // Automatically download the video
    };

    // Start recording
    recorder.start();
    setMediaRecorder(recorder);
    setIsRecording(true);
    toast.success('Recording started!');
  } catch (err) {
    console.error('Error starting recording:', err);
    toast.error('Failed to start recording.');
  }
};

//Stop screen recording
  const stopRecording = () => {
  if (mediaRecorder) {
    mediaRecorder.stop();
    setIsRecording(false);
    toast.success('Recording stopped and downloaded!');
  }
};


  return (
      <div className="full">

      {showVideo && (
          <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
            <video
              ref={videoRef} // Assign the videoRef to the video element
              src={videoOnStart} // initial video source
              autoPlay
              playsInline
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                objectFit: 'cover',
                zIndex: 9999,
          }}
          onEnded={() => restartAppAfterVideoEnd()}
          controls={false}
        />


        <button 
          onClick={stopVideo} 
          style={{
            backgroundColor: 'rgb(60, 93, 170)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '12px',
            border: 'none',
            padding: '8px',
            position: 'absolute',
            top: '20px',
            right: '20px',
            cursor: 'pointer',
            zIndex: 10000000,
            pointerEvents: 'all',
          }}
        >
          <img
            src="/stopvideo.png" // Path to your close icon image
            alt="Stop video"
            style={{
              width: '42px', // Adjust the icon size as needed
              height: '32px',
              color: 'transparent',
            }}
          />
        </button>

              </div>
            )}


      <div style={{
          position: 'fixed',
          bottom: window.innerWidth < 768 ? '74px' : '20px', // Adjust this value to change how far from the bottom the boxes appear
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
          zIndex: 10000 // Ensure it's above other content except the video overlay
      }}>

     {integration && JSON.parse(integration).videos && JSON.parse(integration).videos.length >2 && displayedIndices.map((videoIndex) => {
        const video = JSON.parse(integration).videos[videoIndex];

        return (
          <div
            key={videoIndex}
            style={{
              width: window.innerWidth < 768 ? '80px' : '100px', // Smaller width for mobile
              height: window.innerWidth < 768 ? '80px' : '100px', // Smaller height for mobile
              backgroundColor: 'rgba(0,0,0,.5)',
              padding: '16px',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              borderRadius: '8px',
              textAlign: 'center',
              marginTop: window.innerWidth < 768 ? '-20px' : '0px', // Move higher on mobile
            }}
            onClick={() => handleVideoClick(videoIndex)}
          >
            {video.text}
          </div>
        );
      })}

</div>


    {showPopup && (
      <Popup 
        onClose={() => setShowPopup(false)} 
        onOkay={handleOkayClick} 
        popupTitle={popUpTitle} 
        popupText={popUpText} 
        popupBtn={popUpButtonText} 
        setSelectedLanguage={setSelectedLanguage} 
        isLoading={isLoading} 
        isAnimationLoaded={isAnimationLoaded} 
        parsedLanguages={parsedLanguages}
        profilePicture={profilePicture}
      />
    )}    <ToastContainer
      position="top-left"
      autoClose={4000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
     {speak || load ? 
          <div style={STYLES.area}>
            <button style={STYLES.speak}>
              {speak || load ? 'Thinking...' : ''}
            </button>
          </div>

         : '' 
        }
    <div className='about' onClick={() => setShowModal(!showModal)}>
      <img src={s3_bucket + '/images/icons/menu.png'} alt='menu'></img>
    </div>
    <div className='modal' style={{ display: showModal ? 'flex' : 'none' }}>
      <img src={companyLogoLink} width="100px" height="100px"></img>
      <p style={{ marginTop: '10px' }}>{companyShortOverview}</p>
      <a style={{ padding: '10px' }} className='repo' href='https://nevronix.ai' target='_blank'> </a>
      <a href='https://nevronix.ai' target='_blank' style={{ marginBlock: "5px" }}>Powered by Nevronix.AI</a>
    </div>
    <div className='chat-div'>
      <div className='chat-box'>
        {chats.map((chat, index) => (
          <p key={index} className={chat.who}>
            {chat.msg}
            {chat.who === "bot" && <div className='time'>{"generated in " + chat.exct + "s"}</div>}
          </p>
        ))}
        {(load || speak) && !playing && (
          <p style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
            <lottie-player src="./EQt3MHyLWk.json" style={{ width: "50px", height: "50px" }} loop autoplay speed="1.4" direction="1" mode="normal"></lottie-player>
          </p>
        )}
      </div>

     <div className='msg-box'>
        {parsedLanguages.length > 1 && (
          <CustomDropdownInternal options={parsedLanguages} selectedValue={selectedLanguage} onChange={(e) => handleSelectChange(e)} />
        )}
        <button ref={micRef} className='msgbtn' id={`mic${isListening ? '_active' : ''}`} onClick={microphone_triger}>
          <img ref={imgMicRef} src={s3_bucket + "/images/icons/mic.png"} alt='mic' unselectable='off'></img>
        </button>
        <input type='text' value={msg} onChange={e => setMsg(e.target.value)} onKeyDown={e => e.key === 'Enter' && getResponse(msg)} placeholder='  Talk or type here...'></input>
        <button className='msgbtn' id='send' onClick={() => getResponse(msg)}>
          <img src={s3_bucket +'/images/icons/send.png'} alt='send'></img>
        </button>
      </div>

    </div>

   
    <ReactAudioPlayer
    src={audioSource}
    ref={(element) => {
      audioPlayer.current = element;
      audioElementRef.current = element?.audioEl?.current;
    }}
    crossOrigin="anonymous"
    onEnded={playerEnded}
    onCanPlayThrough={playerReady}
    controls={false} 
/>
    {!isLoading ? (
      <Canvas
        dpr={2}
        shadows
        style={{ width: '100vw', height: '100vh' }} // Full page canvas
        onCreated={({ gl }) => {
          gl.physicallyCorrectLights = true;
          gl.outputEncoding = sRGBEncoding;

               // Attach ref to the canvas
            canvasRef.current = gl.domElement;

          // Initialize WebGLRenderer
          renderer.setPixelRatio(window.devicePixelRatio);
          renderer.setSize(window.innerWidth, window.innerHeight);

          renderer.shadowMap.enabled = true;
          renderer.shadowMap.type = THREE.PCFSoftShadowMap; // or THREE.PCFShadowMap
          renderer.shadowMapSize = 1024; // Increase this for better quality

          renderer.outputEncoding = sRGBEncoding;
          renderer.physicallyCorrectLights = true;
          renderer.toneMapping = THREE.ACESFilmicToneMapping;
          renderer.toneMappingExposure = 1.0;

          const pmremGenerator = new THREE.PMREMGenerator(renderer);
          pmremGenerator.compileEquirectangularShader();
        }}
      >
        <PerspectiveCamera
          makeDefault
          fov={cameraFov}
          position={[cameraLeftRight, cameraUpDown, cameraForwardBackward]} // Move camera higher and closer to focus on the face
        />
        <Suspense fallback={null}>
          <Environment background={true} files={hdriDefault} />
        </Suspense>
        <Suspense fallback={null}>
          <Bg background={background} />
        </Suspense>
        <Suspense fallback={null}>
          <Avatar
            avatar_url={decryptedGlbUrl}
            speak={speak}
            setSpeak={setSpeak}
            text={text}
            setAudioSource={setAudioSource}
            playing={playing}
            setIsAnimationLoaded={setIsAnimationLoaded}  
            audioPlayer={audioPlayer}
          />
        </Suspense>
      </Canvas>
    ) : (
      <div></div>
    )}
    
  </div>
);
}

function Bg({ background }) {
  const texture = useTexture(background);
  const { viewport, size } = useThree();

  const aspect = size.width / size.height;
  const isMobile = size.width < 600;

  return (
    <mesh
      position={[0, viewport.height, -5]}
      scale={[
        aspect * viewport.height * 2,
        isMobile ? aspect * viewport.height * 5.3 : aspect * viewport.height * 1.3,
        1,
      ]}
    >
      <planeBufferGeometry args={[1, 1]} />
      <meshBasicMaterial map={texture} />
    </mesh>
  );
}

export default App;